import { gql, useApolloClient } from '@apollo/client'
import { DropdownOption } from '@obeta/models/lib/models/UI'

import {
  UpdateOrderMetaDataInput,
  UpdateOrderMetaDataMutation,
  UpdateOrderMetaDataOutput,
} from '@obeta/schema'
import { useTranslation } from 'react-i18next'

const UPDATE_ORDER_META_DATA_MUTATION = gql`
  mutation updateOrderMetaData($input: UpdateOrderMetaDataInput!) {
    updateOrderMetaData(input: $input) {
      success
      errorCode
      errorMessage
      orderId
    }
  }
`

export const useOrders = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const getListSearchFieldOptions = (): DropdownOption[] => {
    return [
      {
        label: t('ORDERS.LIST.SEARCH.OPTIONS.ALL'),
        value: 'Default',
      },
      {
        label: t('ORDERS.LIST.SEARCH.OPTIONS.COMMISSION'),
        value: 'CommissionText',
      },
      {
        label: t('ORDERS.LIST.SEARCH.OPTIONS.CUSTOMER_REFERENCE'),
        value: 'CustomerReference',
      },
    ]
  }

  const updateMetaData = async (
    input: UpdateOrderMetaDataInput
  ): Promise<UpdateOrderMetaDataOutput | undefined> => {
    const response = await client.mutate<UpdateOrderMetaDataMutation>({
      mutation: UPDATE_ORDER_META_DATA_MUTATION,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
    return response.data?.updateOrderMetaData
  }

  return {
    getListSearchFieldOptions,
    updateMetaData,
  }
}
